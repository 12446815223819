<template>
  <div class="card">
    <div id="content" class="md:w-9/12">
      <div class="mb-2">
        <slot name="card-header"></slot>
      </div>
      <div class="card-body">
        <slot name="card-body"></slot>
      </div>
      <div class="mt-3">
        <slot name="card-footer"></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  padding: 2rem;
  background: #ffff;
}

#content {
  margin: auto;
}

.card-header,
.card-footer {
  border: none;
}
</style>
