<template>
  <Card class="w-full lg:w-2/4">
    <template v-slot:card-header>
      <div id="logo" class="mt-3">
        <Logo :path="logo" width="100" className="flex justify-center"></Logo>
      </div>
      <StepProgressBar :steps="3" :stepSelected="1" class="mt-8" />
    </template>

    <template v-slot:card-body>
      <h5 class="text-center mt-8 font-semibold text-xl">
        WELCOME! LET'S SETUP YOUR PROFILE
      </h5>
      <form class="mt-8" @submit.prevent="handleSubmitRegister">
        <div class="form__div">
          <input class="form__input" type="text" placeholder=" " id="firstName" v-model="firstName" required />
          <label for="firstName" class="form__label">First name</label>
        </div>
        <div class="form__div">
          <input class="form__input" type="text" placeholder=" " id="lastName" v-model="lastName" required />
          <label for="lastName" class="form__label">Last name</label>
        </div>
        <div class="form__div">
          <input class="form__input" type="email" id="email" placeholder=" " v-model="email" required />
          <label for="email" class="form__label">Email</label>
        </div>
        <div class="form__div">
          <input class="form__input" type="text" id="phoneNumber" placeholder=" " v-model="phoneNumber" required />
          <label for="phoneNumber" class="form__label">Phone number</label>
        </div>
        <div class="form__div">
          <input class="form__input" type="password" placeholder=" " id="password" v-model="password"
            @click="changeShowPasswordReq()" @focus="changeShowPasswordReq()" required />
          <label for="password" class="form__label">Password</label>
          <div v-if="showPasswordReq" class="absolute -left-72 -top-2/3 p-4 border-2 bg-white">
            <span class="absolute top-1/4 w-4 h-4 transform -rotate-45 border-b-2 border-r-2 z-10 bg-white"
              style="left: 98%"></span>
            <p>To make your password stronger:</p>
            <ol class="list-disc list-inside m-2">
              <li>Min 8 characters</li>
              <li>Min 1 number</li>
              <li>Min 1 capital letter</li>
              <li>Min 1 special character</li>
            </ol>
          </div>
        </div>
        <div class="form__div">
          <input class="form__input" type="password" placeholder=" " id="confirm-password" v-model="confirmPassword"
            required />
          <label for="confirm-password" class="form__label">Confirm password</label>
        </div>
        <div class="flex items-center">
          <input class="ml-1" type="checkbox" v-model="acceptedTermsAndCond" />
          <span class="ml-2">
            I agree with
            <a href="https://www.mastermindsports.com/membership-terms" target="_blank" class="underline font-semibold">
              terms of service
            </a>
          </span>
        </div>
        <div>
          <input class="ml-1" type="checkbox" v-model="isAdult" />
          <span class="ml-1"> I acknowledge that I am 18+ </span>
        </div>

        <span class="mt-12 text-xs italic text-justify">
          We are committed to your privacy. By submitting your information, you
          give Mastermind Sports consent to contact you about our program by
          email, phone or text message. For more information, check out our
          <a href="https://www.mastermindsports.com/privacy-policy" target="_blank" class="underline font-semibold">
            privacy policy
          </a>
        </span>
        <button id="b-signup" class="text-color" type="submit"
          :disabled="!(acceptedTermsAndCond && isAdult) || props.proccessingSignup">
          <em v-if="props.proccessingSignup" class="fa fa-spinner fa-spin"></em>
          Sign Up
        </button>
      </form>
      <Modal 
        :show="showModal"
        :cancel="true"
        cancelText="Forgot password"
        confirmationText="Login"
        @cancel="handleCancel" 
        @confirm="handleConfirm"
      >
        <template v-slot:header>
          <h2 class="font-bold mb-4">Oops, this email is already registered</h2>
        </template>
      </Modal>
    </template>
    <template v-slot:card-footer>
      <div class="text-center text-color m-0">
        Already have an account? |
        <router-link :to="{ name: 'Login' }" class="text-color underline">Login</router-link>
      </div>
    </template>
  </Card>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from "vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

import Card from "@/components/auth/Card.vue";
import Logo from "@/components/auth/Logo.vue";
import StepProgressBar from "@/components/general/StepProgressBar.vue";
import Modal from '@/components/general/ConfirmationModal.vue';

import { formatPhoneNumber } from "@/utils/formatPhoneNumber.js";

const router = useRouter();
const toast = useToast();

const emit = defineEmits(["registerEvent"]);

const props = defineProps({
  emailProp: {
    type: String,
    default: null,
  },
  error: {
    type: String,
    default: null,
  },
  proccessingSignup: {
    type: Boolean,
    default: false,
  },
  showModal: {
    type: Boolean,
    default: false,
  }
});

const firstName = ref(null);
const lastName = ref(null);
const email = ref(props.emailProp);
const phoneNumber = ref("");
const password = ref(null);
const passwordRegex = ref(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$ %^&*-.]).{8,}$/
);
const confirmPassword = ref(null);
const showPasswordReq = ref(false);
const logo = ref(require("@/assets/icons/mm-icon.svg"));
const acceptedTermsAndCond = ref(false);
const isAdult = ref(false);

watch(password, (newPassword) => {
  const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[#?!@$ %^&*-.]).{8,}$/;
  if (reg.test(newPassword)) {
    showPasswordReq.value = false;
  }
});

function handleSubmitRegister() {
  if (validatePassword()) {
    emitRegisterEvent();
  }
}

function validatePassword() {
  if (password.value !== confirmPassword.value) {
    showError("Passwords do not match.");
    return false;
  }

  if (password.value.length < 8) {
    showError(
      "This password is too short. It must contain at least 8 characters."
    );
    return false;
  }

  if (!passwordRegex.value.test(password.value)) {
    showError("Password does not satisfy the requirements.");
    return false;
  }
  return true;
}

function changeShowPasswordReq() {
  showPasswordReq.value = true;
}

function showError(errorMess) {
  toast.error(errorMess)
}

function emitRegisterEvent() {
  emit("registerEvent", {
    firstName: firstName.value,
    lastName: lastName.value,
    email: email.value,
    password: password.value,
    fromWeb: false,
    phoneNumber: `+1${phoneNumber.value}`
  });
}

function handleCancel() {
  router.push({
    name: "Forgot Password",
    query: {
      email: email.value,
    },
  });
}

function handleConfirm() {
  router.push({
    name: "Login",
    query: {
      email: email.value,
    },
  });
}

watch(phoneNumber, (newValue, oldValue) => {
  const formatted = formatPhoneNumber(newValue);
  if (newValue !== formatted) {
    phoneNumber.value = formatted;
  }
});
</script>

<style scoped>
#b-signup:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

#b-signup {
  background-color: #edfe86;
  border: none;
  padding: 11px;
  width: 100%;
  margin-top: 2.5rem;
  cursor: pointer;
}
</style>
